import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { SearchBox } from 'components/search/SearchBox';
import { Paragraph } from 'components/core/Typography';
import { StickyContainer } from 'components/core/StickyContainer';

import './StickySearchBar.scss';

export default function StickySearchBar({
	handleSearch,
	triggerId,
	// sf_12700_sfdc_paid_landing_refresh
	clickToCall,
	getTollfreePhoneNumber,
	parentPhoneNumber,
	setParentPhoneNumber,
	parentPhoneLoaded,
	setParentPhoneLoaded
}) {
	// pass the id of the component you want to
	// activate the Search Bar as triggerId prop

	// otherwise, the sticky search bar will be activated
	// when scrolled to where it is rendered on the page
	return (
		<StickyContainer triggerId={triggerId}>
			<div className={classnames('sticky-search-bar', { clickToCall })}>
				<Paragraph size="medium" className="search-box-label" color="black" last>
					Find Storage Units Near You
				</Paragraph>
				<SearchBox
					inputLabel="Enter ZIP code"
					handleSearch={handleSearch}
					segmentPrefix="sticky search box"
					hideOnScroll
					buttonText={
						<span>Find storage</span>
					}
					darkButton
					// sf_12700_sfdc_paid_landing_refresh
					clickToCall={clickToCall}
					getTollfreePhoneNumber={getTollfreePhoneNumber}
					parentPhoneNumber={parentPhoneNumber}
					setParentPhoneNumber={setParentPhoneNumber}
					parentPhoneLoaded={parentPhoneLoaded}
					setParentPhoneLoaded={setParentPhoneLoaded}
				/>
			</div>
		</StickyContainer>
	);
}

StickySearchBar.propTypes = {
	handleSearch: PropTypes.func,
	triggerId: PropTypes.string,
	// sf_12700_sfdc_paid_landing_refresh
	clickToCall: PropTypes.bool,
	getTollfreePhoneNumber: PropTypes.func,
	parentPhoneNumber: PropTypes.object,
	setParentPhoneNumber: PropTypes.func,
	parentPhoneLoaded: PropTypes.bool,
	setParentPhoneLoaded: PropTypes.func
};
