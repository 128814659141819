import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isString, noop } from 'lodash';
import classnames from 'classnames';
import Segment, { SegmentConfig } from '@sparefoot/segment-react';

import { Button } from 'components/core/Button';
import { DatePicker } from 'components/core/DatePicker';
import { GoogleAutocomplete } from 'components/core/GoogleAutocomplete';
import { withSegmentContext } from 'components/core/library/segment';
import { Paragraph } from 'components/core/Typography';
import { ClickToShowNumberButton } from 'components/contact/ClickToShowNumberButton';

import './searchBox.scss';

const enhance = withSegmentContext;

// TODO: Recompose
export class SearchBox extends PureComponent {
	static propTypes = {
		handleSearch: PropTypes.func,
		datePickerLabel: PropTypes.string,
		inputLabel: PropTypes.string,
		reservationWindow: PropTypes.instanceOf(Date),
		moveInDate: PropTypes.instanceOf(Date),
		location: PropTypes.string,
		withDatePicker: PropTypes.bool,
		segmentConfig: PropTypes.object,
		darkButton: PropTypes.bool,
		buttonText: PropTypes.node,
		buttonIcon: PropTypes.func,
		id: PropTypes.string,
		hideOnScroll: PropTypes.bool, // sf_11267_sticky_search_clp_hero
		// sf_12700_sfdc_paid_landing_refresh
		clickToCall: PropTypes.bool,
		getTollfreePhoneNumber: PropTypes.func,
		parentPhoneNumber: PropTypes.object,
		setParentPhoneNumber: PropTypes.func,
		parentPhoneLoaded: PropTypes.func,
		setParentPhoneLoaded: PropTypes.func

	};

	static defaultProps = {
		handleSearch: noop,
		datePickerLabel: 'Check in',
		location: '',
		buttonText: 'Search',
		inputLabel: 'Enter address, city or zip',
		hideOnScroll: false, // sf_11267_sticky_search_clp_hero
		clickToCall: false // sf_12700_sfdc_paid_landing_refresh
	};

	constructor(props) {
		super(props);
		this._setRef = this._setRef.bind(this);
		this.state = {
			locationParams: { location: props.location },
			date: props.moveInDate
		};
	}

	componentWillReceiveProps({ location }) {
		/* istanbul ignore else */
		if (location !== this.state.locationParams.location) {
			this.setState({ locationParams: { location } });
		}
	}

	_handleDateChange = (evt) => {
		const { value } = evt.target;

		this.setState({ date: value.date });
	};

	_handleGoogle = (locationParams) => {
		this.setState({ locationParams });
	};

	_handleSearch = (event) => {
		event.preventDefault();

		let searchParams;
		const { locationParams, date } = this.state;

		if (locationParams.locationSource) {
			searchParams = locationParams;
		} else if (this.instance) {
			searchParams = { location: this.instance.value };
		} else {
			searchParams = locationParams;
		}

		/* istanbul ignore else */
		if (isString(searchParams.location) && searchParams.location.length) {
			const segmentProps = SegmentConfig.buildTrackingProps(
				this.props.segmentConfig,
				new SegmentConfig({ segmentLabel: 'facility search' })
			);

			Segment.trackClick(segmentProps);

			this.props.handleSearch({ locationParams: searchParams, date });
		}
	};

	_setRef = (ref) => {
		this.instance = ref;
	}

	render() {
		const ButtonIcon = this.props.buttonIcon;
		return (
			<div className="search-box-container"> {/* sf_12700_sfdc_paid_landing_refresh */}
				<form
					className={classnames('search-box', { 'with-date': this.props.withDatePicker })}
					onSubmit={this._handleSearch}
				>
					<GoogleAutocomplete
						label={this.props.inputLabel}
						// Adding an automatically generated ID to avoid a an iOS autofocus bug that happens
						// when there is more than one input field with the same ID on a page
						id={this.props.id || `location-${Math.floor(Math.random() * Math.floor(1000))}`}
						className="location"
						name="location"
						segmentLabel="location input"
						onChange={this._handleGoogle}
						getRef={this._setRef}
						value={this.state.locationParams.location}
						hideOnScroll={this.props.hideOnScroll} // sf_11267_sticky_search_clp_hero
					/>
					<div className="date-search-wrapper">
						{!!this.props.withDatePicker &&
							<DatePicker
								label={this.props.datePickerLabel}
								id="date"
								className="date"
								name="date"
								value=""
								reservationWindow={this.props.reservationWindow}
								selectedDate={this.state.date}
								onChange={this._handleDateChange}
							/>
						}
						<Button
							className="search-button"
							dark={this.props.darkButton}
							type="submit"
							segmentLabel="facility search button"
						>
							{!!ButtonIcon && <ButtonIcon className="icon" />}
							{this.props.buttonText}
						</Button>
					</div>
				</form>
				{this.props.clickToCall && ( // sf_12700_sfdc_paid_landing_refresh
					<div className="call-container">
						<Paragraph
							last
							color="black"
							weight="ultraBold"
						>
							OR
						</Paragraph>
						<ClickToShowNumberButton
							outline
							fetchPhoneNumber={this.props.getTollfreePhoneNumber}
							showAsLink
							size="small"
							segmentLabel="Call to Find Storage"
							label="Call to Find Storage"
							// sf_12700_sfdc_paid_landing_refresh
							parentPhoneNumber={this.props.parentPhoneNumber}
							setParentPhoneNumber={this.props.setParentPhoneNumber}
							parentPhoneLoaded={this.props.parentPhoneLoaded}
							setParentPhoneLoaded={this.props.setParentPhoneLoaded}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default enhance(SearchBox);
